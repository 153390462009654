@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');


:root {
  --primary-color: #650020;
  --primary-category-background: #99131317;
  --primary-sell: #991313;
  --primary-text-color01: #fff;
  --primary-text-color02: #000;
  --primary-font: "Poppins", sans-serif;
  --primary-background: #f4f5f4;
}

body a {
  color: black !important;
  text-decoration: none !important;
}

body {
  background-color: #fff !important;
  overflow-x: hidden;
  font-family: "Red Hat Display", sans-serif;
  padding: 0px !important;
  margin: 0px !important;
}

.breadcamp {
  background-color: #F5E7E7;
  padding: 30px;
}

.body_top {
  margin-top: 135px;
  /* position: relative; */
}

.header {
  background-color: #fff !important;
  box-shadow: 0px 3px 6px #00000029;
}

.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition: all 300ms ease;
  background: var(--theme-deafult);
  box-shadow: 0px 0px 8px #ddd;
  animation: smoothScroll 1s forwards;
}

/* .fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition: all 300ms ease;
  background: var(--theme-deafult);
  box-shadow: 0px 0px 8px #ddd;
  animation: smoothScroll 1s forwards;
} */

.top_nav {
  background-color: #650020;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}

.top_nav p {
  color: #fff;
}

.top_nav img {
  width: 20px;
  height: 20px;
}

.top_nav .line {
  height: 25px;
  border: 1px solid #fff;
}

.bottom_nav {
  padding: 10px;
  background-color: #fff !important;
}

.mainIcon {
  width: 100px;
  height: 75px;
  object-fit: cover;
}

.btn_sig {
  background-color: transparent;
  border: 0px !important;
  font-size: 17px;
  padding: 7px 20px;
  font-weight: 600;
}

.btn_sig:hover {
  color: #fff;
  background-color: #650020;
  border-radius: 10px;
  padding: 7px 20px;
}

.nav_active {
  color: #650020 !important;
  font-weight: 700 !important;
}

.bottom_nav .nav-link {
  font-size: 17px;
  color: #000;
  font-weight: 600;
}

.main_slider {
  width: 100%;
  object-fit: cover;
}

.bot_foot {
  background-color: #FFE5E5;
  padding: 40px;
  /* margin-top: 20px; */
}

.bot_foot h5 {
  color: var(--primary-sell) !important;
  font-weight: 650;
}

.folle img {
  width: 22 px;
  height: 22px;
}


.top_foot {
  background-color: #ecc743;
  height: 146px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_foot button {
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  padding: 12px 22px;
}

.home_slider .swiper-pagination-bullet {
  width: 25px !important;
  height: 5px !important;
  border-radius: 3px !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--primary-sell) !important;
}

section {
  margin-bottom: 40px;
}

.titsksd img {
  width: 130px;
  object-fit: cover;
}

.titsksd h2,
.price-break h2,
.prod-desc h2 {
  font-size: 25px;
}

.schema_main {
  border: 1px solid #D2D2D2;
  border-radius: 30px;
  /* height: 450px; */
  height: 100%;
}

.schema {
  padding: 20px;

  /*  */
}

.schema_main .log {
  width: 50px !important;
  height: 32px !important;
  object-fit: cover !important;
}

.schema_main .top {
  background: url('/public/assets/images/Scheme.png');
  background-repeat: no-repeat;
  background-color: #F5F5F5;
  border-radius: 30px 30px 0px 0px;
  padding-left: 20px;
  padding-top: 20px;
}

.schema img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.schemelen {
  height: 240px;
}

.top_active {
  background: url('/public/assets/images/Scheme.png');
  background-repeat: no-repeat;
  /* background-color: #F5F5F5; */
  border-radius: 30px 30px 0px 0px;
  padding-left: 20px;
  padding-top: 20px;
  background-color: #99131317 !important;
  background-position: bottom;
}

.top_active h4 {
  color: #991313 !important;
}

.modal-open {
  padding: 0px !important;
}

.text-color,
.login-terms p {
  color: #991313 !important;
}

.login-terms p {
  text-align: end;
}

.text-color2 {
  color: #650020 !important;
}

.schema button {
  /* width: 180px;  */
  padding: 10px 25px;
  font-size: 16px;
  color: #fff;
  background-color: #881e41;
  border-radius: 25px;
}

.schema button:hover {
  background-color: #650020;
  color: #ffffff;
}

.schema2 {
  background-color: #F5EEEE;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 0px;
}

.smallBan img {
  border-radius: 10px;
  width: 300px;
  height: 400px;
  object-fit: cover;
}

.wealth_smart {
  background-color: #EFFFE1;
  border-radius: 20px;
  padding: 40px 30px;
}

.wealth_smart img {
  width: 62px;
  height: 60px;
}

.smalImg {
  width: 20px !important;
  height: 20px !important;
}

.wealth_smart button {
  padding: 10px 25px;
  font-size: 18px;
  color: #fff;
  background-color: #000;
  border-radius: 25px;
  border: 1px solid #000;
}

.covert button {
  padding: 7px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  border-radius: 10px;
  border: 1px solid #000;
}

.wealth_smart button:hover {
  border: 1px solid #000;
}

.gaq-B {
  background-color: #991313;
  padding-top: 35px;
  padding-bottom: 35px;
}

.gaq-B h2 {
  color: #fff !important;
}



.leaft {
  width: 244px;
  /* height: 91px; */
  height: 60px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 20px;

}

.G1 {
  background-color: #991313;
  height: 91px;
}

.leaft-head h5 {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.leaft h5 {
  font-size: 16px !important;
}

.G2,
.G2 h5 {
  background-color: #faeeee;

}

.G3 {
  background-color: #f9e8e8;
}

.G4 {
  background-color: #f2d1d1;
}

.G5 {
  background-color: #ecbbbb;
}

.G6 {
  background-color: #e5a4a4;
}

.G7 {
  background-color: #df8d8d;
}

.C1 {
  background-color: #fbee9a;
  height: 91px;
}

.C2 {
  background-color: #efefef;
}

.F1 {
  background-color: #41820a;
  height: 91px;
}

.F2 {
  background-color: #f0f9e9;
}

.F3 {
  background-color: #e2f4d3;
}

.F4 {
  background-color: #c5e9a7;
}

.F5 {
  background-color: #a9de7b;
}

.F6 {
  background-color: #8cd34f;
}

.F7 {
  background-color: #6fc823;
}

.pshycal {
  background-color: #fff;
  padding: 25px;
  border-radius: 20px;
}

.accordion-item:first-of-type>.accordion-header .accordion-button {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
  font-weight: 600;
}

.accordion-item {
  padding: 5px !important;
}

.accordion-button:not(.collapsed) {
  color: #991313 !important;
}

.pshycal img {
  width: 60px;
  height: 60px;
}

.sffsfsf {
  position: absolute;
  top: 0;
}

.sffsfsf img {
  width: 289px;
}

.head_bn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.head_bn {
  position: relative;
}

.head_bn h2 {
  color: #fff !important;
  font-weight: 600;
  font-size: 40px;
}

.delvdd {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 143px;
  width: 143px;
}

.delvdd img {
  width: 40px;
  height: 40px;
}

.delvdd h4 {
  font-size: 18px;
  color: black;
  font-weight: 650 !important;
}

.svvv img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 20px;
}


.says_c .swiper-pagination-bullet {
  width: 25px !important;
  height: 5px !important;
  border-radius: 3px !important;
}



@media (min-width: 992px) {
  .trust .col-lg-2 {
    flex: 0 0 auto;
    width: 20% !important;
  }
}

.gp_lst {
  border-radius: 30px;
  padding: 25px;

}

.gp_lst .one_line {
  font-size: 18px;
}

.gp_lst img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.gp_lst button {
  padding: 7px 20px;
  font-size: 16px;
  color: #650020;
  background-color: #65002026;
}

.gp_lst button:hover {
  color: #fff;
  background-color: #650020;
}

.gp_lst:hover {
  background-color: #FEFCF5;
  border: 1px solid #FFC428 !important;
}

.prdd button {
  color: #fff;
  background-color: #991313;
  padding: 10px 35px;
  font-size: 18px;
  border: 1px solid #991313;
}

.prdd button:hover {
  background-color: transparent;
  color: #991313;
  border: 1px solid #991313;

}

.prdd h2 {
  font-size: 24px;
  font-weight: 650;
}

.prdd h5 {
  font-size: 18px;
}

.rerfs h5 {
  font-size: 16px;
}

.proD {
  width: 100%;
  padding: 25px;
}

@media (min-width:1200px) {
  .proD {
    height: 500px;
    width: 100%;
  }
}

.pro_de img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.min_pro {
  width: 70px;
  height: 70px;
}

.rerfs {
  background-color: #F9F5F0;
  border-radius: 20px;
  padding: 25px;
}

.rerfs img {
  width: 34px;
  height: 34px;
  object-fit: cover;
}

.eeffe {
  background-color: #F8F8F8;
  padding: 20px;
  border-radius: 13px;
}

.eeffe img {
  width: 24px;
  height: 28px;
}

.contact-form .form-control:focus {
  box-shadow: none !important;
}

.eeffe .form-control {
  background-color: #F8F8F8;
  border: 0 !important;
}

/* .select__control {} */

.dots {
  width: 14px;
  height: 14px;
  background-color: #FFC428;
  border-radius: 50%;
}

.table {
  border-radius: 20px;
}

.table thead {
  background-color: #99131312;
}

.simlar_pr {
  padding: 15px;
}

.simlar_pr img {
  width: 140px;
  height: 140px;
  object-fit: cover;
}

.simlar_pr button,
.simlar_pr button:hover {
  color: #650020;
  background-color: #65002026;
  border-radius: 8px;
  padding: 8px 20px;
}

@media (min-width: 992px) {
  .sim .col-lg-2 {
    flex: 0 0 auto;
    width: 20% !important;
  }
}

/* RC Table */
.rc-table {
  box-shadow: inset;
  border-spacing: 0;
}

.rc-table .rc-table-content,
.rc-table .rc-table-body,
.rc-table .rc-table-header {
  /* // @apply border-0; */
  border: 0px !important;
  text-align: center;
  border-radius: 5px;
}

/* // .rc-table tr th {
//   // @apply border-0 border-b border-border-200;
//   // border: 0px !important;
//   border: 1px solid #f7f7f7;
//   // border-bottom: 1px solid #125453;
// } */
.rc-table tr td {
  /* // @apply border-0;
  // border: 0px !important;
  // border: 1px solid #f7f7f7; */
  border: 1px solid #fff;
  border-bottom: 1px solid #f3f4f6;
}

.rc-table thead.rc-table-thead th {
  /* // @apply border-border-200 bg-gray-100 px-4 py-3 font-body text-sm font-bold text-heading; */
  border: 1px solid #dfe8e9;
  padding: 20px;
  font-weight: 600;
}

.rc-table tr.rc-table-row:hover {
  background-color: inherit;
}

.rc-table tr.rc-table-row td {
  /* // @apply px-4 py-3 font-body text-base font-normal text-heading; */
  padding-left: 25px;
  padding-right: 25px;
  padding: 18px;
}

.rc-table tr.rc-table-row:not(.rc-table-expanded-row) {
  cursor: pointer;
}

.rc-table tr.rc-table-expanded-row>td {
  /* // @apply whitespace-normal border border-gray-50 p-0; */
  border: 1px solid #125453;
  padding: 0;
}

.cursor_pointer {
  cursor: pointer !important;
}

.rc-table tr.rc-table-expanded-row tr.rc-table-row {
  cursor: default;
}

.rc-table .rc-table-expanded-row-fixed::after {
  border-width: 0;
}

.rc-table-cell.rc-table-cell-row-hover {
  color: #125453;
  /* // background-color: #f4f7f7; */
  background-color: #f3f4f6;
}

.rc-table tbody tr td {
  background: #F8F4F580;
  font-size: 18px;
  color: #222222;
  font-weight: 600;
}

.rc-table tr th {
  border: 1px solid #125453;
}

.rc-table thead th {
  text-align: center;
  background: #99131312 !important;
  color: #000;
  font-size: 20px;
  color: #991313 !important;
}

/* .con-loc-icon img {
  background-color: #991313;
  max-width: 73px;
  padding: 20px;
  width: 100%;
  border-radius: 52px;
} */

.con-loc-icon img {
  width: 30px;
}

.con-loc-icon {
  background: #991313;
  height: 55px;
  width: 55px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .follow-icon img {
  max-width: 40px;
  width: 100%;
  background-color: #FFE5E5;
  padding: 8px;
  margin: 0px 5px;
  border-radius: 20px;
} */

.follow-icon img {
  width: 20px;
}

.follow-icon {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-bg {
  background: #efe5e8;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.con-office h4 {
  font-size: 19px;
  font-weight: 650;
}

.con-office p {
  font-size: 17px;
  font-weight: 500;
}

.contact-image {
  background-color: #fff2e88a;
  text-align: end;
}

.contact-image img {
  max-width: 725px;
  width: 100%;
}

.contact-form form {
  max-width: 790px;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 32px;
  border-radius: 20px;
  background-color: #fff;
}

.contact-form input,
.contact-form textarea {
  background-color: #b7b2b221;
  padding: 10px;
  border: none !important;
  font-weight: 600;
  color: black;
}

.contact-form input::placeholder {
  color: #817f7f;
  font-weight: 400;
}

.contact-form .form-group,
.contact-form label {
  margin: 10px 0px;
  font-size: 18px;

}

.contact-form button,
.submit button {
  border: none;
  background-color: #991313;
  padding: 10px 30px;
  border-radius: 15px;
  margin: 10px 0px;
  font-size: 16px;
  width: 120px;
}

.contact-form {
  position: absolute;
  top: -78px;
  left: 16%;
}

.contact-form-img {
  margin-top: 150px;
}

.form-select:focus {
  /* border-color: #6e000065;
  outline: 0;
  box-shadow: 0 0 0 .25rem #6e000065; */
  box-shadow: none !important
}

.form-control:focus {
  /* outline: 0; */
  /* border: 1px solid #6e000065 !important; */
  box-shadow: none !important;
}

.contact_map iframe {
  width: 100%;
  height: 450px;
  border: 0px;
  border-radius: 20px;
}

.myaccount-title h2 {
  font-weight: 700;
  font-size: 36px;
}

.bd-form .profice-pic {
  background-color: #b7b2b221;
  padding: 30px 0px;
  border-radius: 20px;
}

.bd-form img {
  /* max-width: 100px; */
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50px;
}

.bd-form {
  border-radius: 15px;
  padding: 10px 15px 15px 15px;
}

.bd-form label,
.contact-form label,
.Addbankacc-modal label {
  color: #706a6a;
  font-weight: 500;
}

.bd-form input,
.bd-form input::placeholder,
.bd-form select {
  color: #000;
  font-weight: 600;
  padding: 12px;
}

.bd-form .form-group,
.bd-form label,
.Addbankacc-modal .form-group,
.a {
  margin: 10px 0px;
}

.bd-head h4::after {
  content: " ";
  border-bottom: 3px solid #991313;
  width: 60px;
  display: block;
  padding-top: 3px;
}



.dashboard-list img {
  max-width: 25px;
  width: 100%;
}

.dashboard-list .page-name h4 {
  font-size: 16px;
  margin: 0px;
}

.dashboard-list {
  border-bottom: 1px solid #524e4e1a;
  padding: 15px 0px;
  cursor: pointer;
}

.customer-pages {
  background-color: #524e4e12;
  border-radius: 15px;
  padding: 10px 25px;
  /* margin: 0px 20px; */
}

.customer-pages .dashboard-list:nth-child(8) {
  border: none;
}

.balance-view2 {
  background-color: brown;
  height: 73px;
  border-radius: 20px;
}

.balance-view1 {
  background-color: #fde6e6;
  height: 140px;
  border-radius: 20px;
}

.balance-view3 {
  background: linear-gradient(360deg, #b19303, #e5bf07, #ebdb17, #ffd300, #ddb807, #c3a514);
  height: 68px;
  border-radius: 18px;
  padding: 22px 15px;
  top: 23%;
  width: 380px;
  left: 41px;
}

.balance-view3 h4 {
  font-size: 18px;
  font-weight: 700;
}

.balance-view3 span {
  font-weight: 500;
  margin: 0px 10px;
}

.bonus-pic img {
  max-width: 111px;
  width: 100%;
}

.bonus-view {
  background: linear-gradient(181deg, #fffcfc, #ffda52);
  border-radius: 20px;
  padding: 13px;
}

.bonus h4,
.sip-title h4 {
  font-weight: 700;
  font-size: 18px;
}

.bonus h5 {
  font-size: 18px;
  font-weight: 700;
}

.bonus span,
.customer-name span,
.sip-subtitle h5,
.form-check span {
  color: #991313;
  cursor: pointer;
}

.bonus button {
  border: none;
  border-radius: 10px;
  font-size: 13px;
}

.actived-sip img {
  max-width: 25px;
}

.actived-sip {
  background-color: #ebd076ad;
  border-radius: 30px;
  color: #997b09;
  padding: 5px 10px;
}

.sip-subtitle h4 {
  font-size: 19px;
  color: #857d7d;
}

.actived-sip h5 {
  font-size: 16px;
}

.sip-schemeplan .plan1 {
  padding: 10px;
  margin: 10px 0px;
}

.sip-schemeplan .plan1 p,
.sip-schemeplan .plan1 h6 {
  margin: 0px;
  padding: 3px 0px;
}

.sip-schemeplan .plan1 h6 {
  font-weight: 700;
}

.sip-schemeplan .plan1 p {
  font-size: 12px;
  font-weight: 500;
}

.sp1 {
  background-color: #edf8e3;
}

.sp2 {
  background-color: #2a58024d;
}

.sp3 {
  background-color: #2a5a007a;
}

.sp4 {
  background-color: #29580091;
}

.sp5 {
  background-color: #3e870096;
  color: #fff;
}

.sp6 {
  background-color: #2b5e00db;
  color: white;
}

.sp7 {
  background-color: #edf9f0;
  color: rgb(0, 0, 0);
}

.sip-tc h5 {
  font-size: 18px;
}

.your-sipscheme {
  background-color: #80808017;
  padding: 20px;
  border-radius: 20px;
}

.myprofile-content .profice-pic {
  border-radius: 20px 20px 0px 0px;
}

.myprofile-content .bd-form {
  border-radius: 21px;
  padding: 0px 0px 15px 0px;
}

.form-control,
.form-select,
.notify-list .row {
  background-color: #b7b2b221;
  border: none;
}

.form-control:focus {
  background-color: #97929226 !important;
}

.myprofile-content input::placeholder,
.kyc-content input::placeholder {
  color: #4b464685;
  font-weight: 400;
}

.update-profile button,
.verify-kyc button {
  border: none;
  background-color: #991313;
  padding: 12px 50px;
  border-radius: 15px;
  margin: 10px 0px;
  font-size: 19px;
}

.verify-kyc button {
  background-color: #49a500c4 !important;
  font-weight: 300 !important;
}

.sip-search input {
  width: 300px;
  padding: 8px;
}

.sip-search img {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  cursor: pointer;
}

.siptable-view img {
  max-width: 20px;
  width: 100%;
  cursor: pointer;
}

.siptable-view td {
  padding: 15px 0px;
  color: #000;
  font-weight: 500;
}

.siptable-view tbody tr:nth-last-child(1) {
  border: transparent;
}

.sipshow-search {
  padding: 15px 32px;
}

.notify-list,
.addbank-ac {
  border-radius: 20px;
}

.siptable-view th {
  background-color: #ffe1e1ad;
  color: #6c0000;
  font-weight: 500;
  padding: 15px 0px;
  font-size: 18px;
}

.sipshow h4 {
  font-size: 20px;
}

/* .notify-img img {
  max-width: 70px;
  width: 100%;
  background-color: #ffdb36;
  border-radius: 70px;
  padding: 12px 1px;
} */

.notify-img img {
  max-width: 65px;
  width: 100%;
  border-radius: 95px;
  height: 65px;
  object-fit: fill;
}

.notify-list .row {
  margin: 10px 0px;
  padding: 15px;
  border-radius: 10px;
}

.notify-sec1 h4 {
  font-size: 20px;
}

.notify-sec1 h6 {
  font-size: 13px;
}

.notify-sec2 p {
  font-size: 14px;
}

.bankimage img {
  max-width: 60px;
  width: 100%;
}

.addbank-btn button {
  border: none;
  padding: 10px 15px;
  background-color: #991313;
  border-radius: 11px;
}

.addbank-info p {
  font-size: 15px;
  color: gray;
}

.addbank-submit button,
.addbank-submit button:hover {
  background: #991313;
  padding: 8px 36px;
}



.customer-pages a {
  color: black !important;
  text-decoration: none !important;
}

.livebuy img {
  max-width: 18px;
  width: 100%;
}

.livebuy h5 {
  font-size: 15px;
}

.livebuy span {
  color: #b99d0b;
  padding: 0px 5px;
  background: rgb(255 244 199);
  border-radius: 5px;
}



input {
  color: black !important;
}

.entergrm-amt {
  background-color: #f5e7e7;
  padding: 10px;
  border-radius: 16px;

}

.entergrm-amt .grm,
.entergrm-amt .amt {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 10px;
}

.term-subit h6 {
  font-size: 12px;
  color: gray;
}

.term-subit button {
  border: none;
  background-color: #991313;
  padding: 10px 26px;
  border-radius: 25px;
}

.goldrate-gm h6 {
  font-size: 13px;
  color: gray;
}

.goldrate-gm h3 {
  font-size: 20px;
  font-weight: 700;
}

.goldsiver-box .nav-link.active {
  border-bottom: 2px solid #991313;
  background-color: white;
  border-radius: 0px;
  color: #991313;
}

.goldsiver-box button {
  color: gray;
  font-weight: 600;
  font-size: 18px;
}

.agent-slide img {
  border-radius: 15px;
  max-width: 120px;
  width: 100%;
}

.agent-enrool img {
  max-width: 14px;
  width: 100%;
  margin: 0px 0px 0px 2px;
}

.agent-enrool button {
  font-size: 13px;
}

.join-newbird {
  background-color: #eaeaff;
  padding: 20px;
  border-radius: 20px;
}

.join-newbird h4 {
  font-weight: 700;
  font-size: 19px;
}

.topper-profile img {
  max-width: 75px;
  width: 100%;
  border-radius: 20px;
}

.topper1 {
  border-bottom: 1px dashed #b1b1b1;
}

.topper1 h5 {
  font-size: 16px;
  font-weight: 800;
}

.topper-board {
  background-color: #ffffe1c4;
  padding: 16px;
  border-radius: 20px;
}

.referal-topper h4,
.event-topper h4 {
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 15px;
}

.agent-dashbord-content .balance-view2 {
  background-color: #03a37c;
}

.agent-dashbord-content .balance-view1 {
  background-color: #00761821;
}

.login-terms span,
.go-signup {
  color: #991313;
  cursor: pointer;
}

.loginmodal button,
.loginmodal button:hover,
.signupmodal button,
.signupmodal button:hover,
.verifymodal button,
.verifymodal button:hover {
  background-color: #991313;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  padding: 10px;
}

.login-footer h4 {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.go-signup {
  font-size: 17px;
}

.verifymodal .login-footer {
  cursor: pointer;
}

.signarrow {
  border: 1px solid #991313;
  color: #991313;
  border-radius: 21px;
  font-size: 19px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  line-height: 20px;
  height: 30px;
  width: 390px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.signarrow:hover {
  background-color: #991313;
  color: white;
  transition: all 0.5s ease-in-out;
}

.loginmodal .modal-content,
.Addbankacc-modal .modal-content {
  border-radius: 25px;
}

.login-header h3 {
  font-weight: 700;
  font-size: 26px;
}

.loginmodal h3::after {
  content: " ";
  display: block;
  border-bottom: 3px solid #991313;
  width: 90px;
  margin: 3px 150px;
}

.loginmodal input {
  border-radius: 10px;
  background-color: white !important;
  padding: 10px 15px;
}

.login-terms input[type="checkbox"] {
  width: 20px;
  /*Desired width*/
  height: 20px;
  /*Desired height*/
  accent-color: #991313;
}

.signidentity input {
  padding: 0px;

}

.check .check-active {
  border: 1px solid #b70000f5;
  padding: 10px 30px 5px;
  border-radius: 14px;
  background-color: #ffd3d359;
}

.check .check-unactive {
  border: 1px solid #e0d9d9f5;
  padding: 10px 30px 5px;
  border-radius: 14px;
  background-color: #ededed7d;
}

.select-identity .form-check-input:checked {
  background-color: #991313;
  border-color: #f79090;
}

.select-identity .form-check-input:focus {
  box-shadow: none !important
}


.select-identity .form-check label {
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}

.signupmodal .modal-content,
.verifymodal .modal-content {
  border-radius: 35px;
}

.signup-detailform input,
.signup-detailform select {
  background-color: white !important;
}

.signup-detailform input,
.signup-detailform select,
.bankdetail-input input,
.bankdetail-input select,
.Addbankacc-modal input,
.Addbankacc-modal select {
  border: 1px solid rgb(219 217 217);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.signup-detailform input::placeholder {
  color: black;
  font-weight: 500;
}

.signupmodal h3::after {
  content: " ";
  display: block;
  border-bottom: 3px solid #991313;
  width: 88px;
  margin: 10px 70px;
}

.signupaftermodal h3::after {
  content: " ";
  display: block;
  border-bottom: 3px solid #991313;
  width: 120px;
  margin: 10px 70px;
}


.otp-box input {
  max-width: 55px;
  width: 100px;
  padding: 15px 8px;
  border-radius: 10px;
}

.verifymodal h3:after {
  content: " ";
  display: block;
  border-bottom: 3px solid #991313;
  width: 84px;
  margin: 5px 305px;
}

.verifymodal .modal-header h4,
.verifymodal .login-terms h6 {
  font-size: 15px;
}

.verifymodal input:focus {
  outline: none;
}

.border-loc {
  border-left: 1px solid #3f38383b;
  border-right: 1px solid #3f38383b;
}

.goldsiver-box {
  max-width: 520px;
  /* max-width: 450px; */
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  position: absolute;
  bottom: -44px !important;
  z-index: 99;
  left: 160px !important;
}

.customer-pages {
  display: block;
}

.mb-pages {
  display: none;
}

.schemebtn-1 {
  padding: 0px 20px !important;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wealth_smart h5 {
  font-size: 18px;
}



@media (max-width:1200px) {
  .goldsiver-box {
    position: unset !important;
  }
}

.goldsiver-box button {
  font-size: 22px;
}

.goldsiver-box button:hover {
  color: #991313;
}


input[type=checkbox] {
  width: 20px;
  height: 20px;
}

.form-check-input {
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #ba0a0a;
  border-color: #ba0a0a;
}

.home_slider {
  position: relative;
}

.top-drop img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.logout button:nth-child(1),
#frgtpwd_modal button,
#resetmodal button {
  background-color: #991313;
  border: none;
}

.forgotmodal h3::after {
  content: " ";
  display: block;
  border-bottom: 3px solid #991313;
  width: 88px;
  margin: 4px 165px;
}

.resetmodalbox h3::after {
  content: " ";
  display: block;
  border-bottom: 3px solid #991313;
  width: 88px;
  margin: 0px 250px !important;
}

.forgotmodal span {
  color: #991313;
  cursor: pointer;
}

#frgtpwd_modal input,
#resetmodal input {
  padding: 10px 15px;
  border-radius: 10px;
  background-color: white;

}

#frgtpwd_modal button,
#resetmodal button {
  background-color: #991313;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  padding: 10px;
}

#load {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  background-repeat: no-repeat;
  text-align: center;
  background: url('/public/assets/Appboy\'s\ Golden\ Coins.gif');

}


.paynsd,
.paynsd:hover {
  background: #650020;
  color: #fff;
}

/* .paynsd2 {
  background-color: #65002057;
  pointer-events: none;
} */
.paynsd-new {
  color: #650020;
  border: 1px solid #650020;
  background-color: whiyte;
}

.paynsd-new:hover {
  background: #650020;
  color: #fff;
  border: 1px solid #650020;
}

.cancel-btns {
  border: 1px solid #dc3545;
  color: #dc3545;
}

.cancel-btns:hover {
  border: 1px solid #dc3545;
  background-color: #dc3545;
  color: #fff;
}


.ytjsfvdjd {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.logouticon {
  color: #991313;
  font-size: 40px;
}

.logout-text {
  font-size: 20px;
  color: black;
  font-weight: 650;
}

.logout button {
  font-size: 16px;
  padding: 7px 40px;
}

.gold-slide img {
  width: 45px;
}

.gold-slide .active {
  background-color: #16151517 !important;
}

.dashboard-list .active {

  color: #991313;
}

.spinner-border {
  margin: 0px 22px;
}

.post-bg1 {
  background-color: #ecf5fc;
  min-height: 150px;
  padding: 20px;
  border-radius: 10px;
}

.post-bg2 {
  background-color: #feeeee;
  min-height: 150px;
  padding: 20px;
  border-radius: 10px;
}

.post-bg3 {
  background-color: #edf9f0;
  min-height: 150px;
  padding: 20px;
  border-radius: 10px;
}

.totalprop span {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

.fw600 {
  font-weight: 600 !important;
}

#resetmodal .modal-content,
#frgtpwd_modal .modal-content {
  border-radius: 25px;
}

.aclist h2 {
  font-size: 16px;
  margin-bottom: 25px;
  font-weight: 600;
  color: black;
}

.aclist {
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.aclist button,
.aclist button:hover,
.addbank-submit button,
.update-profile button,
.verify-kyc button,
.addbank-submit button:hover {
  border: none;
  background-color: #991313;
  padding: 10px 30px;
  border-radius: 15px;
  margin: 10px 0px;
  font-size: 16px;
  /* width: 120px; */
}


.kjhgddd img {

  object-fit: cover;
}

.kjhgddd {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  padding: 4px;
}

.detail-imges .swiper-slide {
  width: 75px !important;
  margin-top: 20px;
  margin-right: 0px;
}

.detail-imges .tab-pane img {
  height: 450px;
  padding: 10px;
  object-fit: contain;
}

.detail-imges button {
  background-color: #e0dbdb;
}

.detail-imges .swiper-wrapper {
  justify-content: center !important;
}


.one_line {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.two_line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.three_line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.four_line {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.gold-activebtn {
  width: 109px;
}

.gold-cancellbtn {
  width: 95px;
}

.totaltrans {
  color: #991313;
  font-weight: 700;
  font-size: 23px;
}

.collapsed .top_active {
  background-color: #b3a9a917;
  color: black;
}

.termsborder {
  color: #991313;
  cursor: pointer;
  font-weight: 700;
}

.paybox {
  height: 500px;
}

.tabledata {
  width: 100% !important;

}

.MuiDataGrid-columnHeaderTitle {
  color: #6c0000;
  font-weight: 500;
  font-size: 16px;
}

.MuiDataGrid-columnHeader {
  background-color: #ffe1e1ad;
  font-size: 14px;
}

/* .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
} */

.schemedata-table .MuiDataGrid-row:nth-child(even) {
  background-color: #f9f9f9;

}

.MuiDataGrid-columnHeaderTitle {
  font-size: 13px;
}

/* .MuiDataGrid-cell {
  text-align: center !important;
} */

.siptrans-table {
  height: 500px;
  border-radius: 8px;
}

.popactive-img img {
  width: 65px;
  padding: 10px;
  border-radius: 64px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #6c0000;
}

.popdelete img {
  width: 65px;
  padding: 10px;
  border-radius: 64px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.poptitle h5 {
  color: #6c0000 !important;
  font-size: 22px;
}

.siptrans-head h4,
.mybank-head h4,
.bd-heads h4,
.notificatin-head h4 {
  color: #6c0000;
  font-weight: 650;
  font-size: 20px;
}

.pop-submsg h4,
.pop-submsg2 h4 {
  font-size: 17px;
  font-weight: 600;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within,
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiSelect-select,
.MuiTablePagination-selectLabel,
.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  display: none !important;
}

.css-r5in8e-MuiInputBase-root-MuiInput-root::after {
  border-bottom: none !important
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

.docimg {
  border-radius: 0px !important;
  padding: 10px !important;
  border-radius: 20px !important
}

.diselect {
  top: 13px;
  left: 65px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 0px 3px;
  background-color: #0000005e;
}

.MuiDataGrid-toolbarQuickFilter {
  background-color: #ebebeb;
  margin: 8px !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
}

.MuiDataGrid-toolbarQuickFilter {
  border-bottom: none !important;
}

.aclist {
  padding: 15px 25px;

}

.MuiInputBase-adornedEnd:hover {
  border: none !important;
}

.prod-desc li {
  font-size: 18px;
}

.active-status {
  background-color: #d5e3d5;
  padding: 4px 25px;
  border-radius: 7px;
  font-size: 14px;
  color: #007f00ad;
  font-weight: 600;
}

.secondplan-btn {
  width: 123px;
  height: 38px;
}

.leaft2 {
  width: 180px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.leaft2 h5 {
  font-size: 15px;
}

/*  */

.terms-text {
  position: relative;
  margin-bottom: 25px;
  color: #650020 !important;
  font-size: 26px;
  font-weight: 600;

}

/* .terms-text::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 192px;
  background: #650020;
  bottom: -7px;
} */

h2 {
  color: #000 !important;
}

.chart-sub {
  font-size: 18px;
}

.wealth_smart h2 {
  font-weight: 700;
}



h5 {
  color: #000 !important;
}

.three_line {
  margin-bottom: 0px;
}

.convert-head {
  color: black;
  font-size: 30px;
  line-height: 46px;
}

.uselink h6:hover,
.schemename {
  color: #650020;
}

.login-text label {
  color: #000;
  font-weight: 500;
  margin-bottom: 5px;
}

.login-text input::placeholder,
.form-control[type=file] {
  color: #a19f9f !important;
}

.reset-model {
  max-width: 700px;
}

.schemebtn {
  background-color: #ffe5e5;
  color: #000000;
}

.scheme-qus button {
  border-radius: 50px !important;
  padding: 16px 30px;
  font-weight: 700;
}


.schemebtn.nav-link.active {
  color: #930202 !important;
  font-weight: 700;
  background: linear-gradient(360deg, #b19303, #e5bf07, #ebdb17, #ffd300, #ddb807, #c3a514);
  padding: 16px 30px;
}

.gaq-B .nav {
  gap: 10px;
}

.gaq-B .nav-link:focus,
.nav-link:hover {
  color: black;
}


.about-content h2 {
  line-height: 46px;
}

.about-content h4 {
  font-size: 18px;
  color: #6E6E6E !important;
  margin-bottom: 25px;
}

.pshycal h5 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.pshycal p {
  color: #000000 !important;
  font-size: 16px;
}

.covert {
  padding-top: 50px;
}

.product-list-page {
  padding-top: 20px;
}

.con-office h4 {
  color: #000 !important;
}

.btn-bor {
  border: 1px solid #650020;

}

.prod-listview::marker {
  color: yellow !important;
}

.pop-cancel-icon img {
  width: 50px !important;
}

.login-text .text-danger {
  font-size: 13px;
}

.cancel-msg {
  width: 340px;
}


.status1 {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.status2 {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.status3 {
  background: linear-gradient(45deg, #FFB64D, #ffcb80);
}

.status4 {
  background: linear-gradient(45deg, #FF5370, #ff869a);
}

.status5 {
  background-color: rgb(83 83 83 / 49%);
}

.plan-viewcard h5 {
  font-size: 30px;
  color: white !important;
}

.plan-count h4 {
  font-size: 16px;
  font-weight: 650;
  color: white;
}

.plan-count h2 {
  font-size: 20px;
  font-weight: 700;
  color: white !important;
}

.plan-viewcard {
  position: relative;
  padding: 10px 15px;
  border-radius: 10px;
  padding-bottom: 30px;
  cursor: pointer;
}

.icon-position {
  position: absolute;
  top: 6px;
  right: 10px;
  color: white;
  font-size: 17px;
}

.cox-xl-33 {
  flex: 20%;
  max-width: 20%;
}

@media screen and (width < 1200px) {
  .cox-xl-33 {
    flex: 25%;
    max-width: 25%;
  }
}

@media screen and (width < 992px) {
  .cox-xl-33 {
    flex: 25%;
    max-width: 25%
  }
}

@media screen and (width < 768px) {
  .cox-xl-33 {
    flex: 35%;
    max-width: 32%
  }
}

@media screen and (width < 576px) {
  .cox-xl-33 {
    flex: 45%;
    max-width: 50%
  }
}


/* .react-datepicker__navigation {
  background-color: white !important;
  border: none;
  font-size: 18px;
  width: 0% !important;
}

.react-datepicker__input-container input {
  width: 300px !important;
}

.react-datepicker__input-container input:focus-visible {
  outline: 0px !important;
}

.react-datepicker__navigation--next {
  display: none !important;
}

.react-datepicker__navigation--previous {
  display: none !important;
} */

.select__input-container {
  margin: 0px !important;
}

.select__value-container {
  /* padding: 6px 0px !important; */
  padding: 8px 9px !important;
}

.select__control {
  border-radius: 10px !important;
}

.termpagesize p {
  font-size: 16px !important;
  font-family: "Red Hat Display", sans-serif !important;
  color: black !important;
}

.termpagesize h2 {
  font-size: 21px !important;
  font-family: "Red Hat Display", sans-serif !important;
  color: #6c0000 !important;
}

.termpagesize h1 {
  font-family: "Red Hat Display", sans-serif !important;
}


.termpagesize h3 {
  font-family: "Red Hat Display", sans-serif !important;
}


.termpagesize h4 {
  font-family: "Red Hat Display", sans-serif !important;
}


.termpagesize h5 {
  font-family: "Red Hat Display", sans-serif !important;
}


.termpagesize h6 {
  font-family: "Red Hat Display", sans-serif !important;
}

.select__menu {
  height: 210px !important;
  overflow-y: hidden;
}

.dob-select input {
  background-color: #efefef;
  border: none !important;
  border-radius: 10px;
}

.plan-viewcard-unactive {
  background-color: #ffebeb;
}

.plan-count-unactive,
.icon-position-unactive {
  color: black;
}



.razoricon img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.schemedata-table .MuiDataGrid-row:hover {
  background-Color: "inherit" !important;
}

.statustact {
  border: 2px solid #991313;
  border-radius: 11px;
  padding: 6px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cancle-histry {
  padding: 20px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 8px;
  margin: 10px 0px;
  background-color: #edf9f0;
  text-align: justify;
}

.cancle-histry h5 {
  font-size: 16px;
}

.cancbg1 {
  min-height: 150px !important;
}

.cancbg1 .totaltrans {
  color: #991313;
  font-weight: 700;
  font-size: 16px !important;
}

.cancbg1 .totalprop h4,
.pm-ti h5 {
  font-size: 17px !important;
}

.cancbg1 .totalprop h6 {
  font-size: 12px;
}

.pm-choice .form-check {
  border: 1px solid #b0b5b9;
  border-radius: 10px;
  min-height: 60px;
  margin: 20px 0px;
  cursor: pointer;
}

.pm-choice .act {
  border: 1px solid #991313;
}

.pm-choice label {
  font-size: 14px;
  font-weight: 650;
}

.qr-img img {
  width: 150px;
  height: 150px;
}

.qr-deatil1 h5 {
  font-size: 18px;
}

.qr-detail h6 {
  font-size: 20px;
  margin-bottom: 20px;
}

.qr-upiid {
  /* border: 1px solid #e5e3e3; */
  background-color: #e1e1e1;
  border-radius: 10px;
  padding: 10px 15px;
  width: max-content;
}

.qq-sacn-img img {
  width: 150px;
  height: 150px;
}

.qq-sacn-img {
  text-align: center;
}

.uplo-img img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.uplo-img button,
.uplo-img button:hover {
  background-color: transparent;
  border: 1px solid #cfcfcf;
  color: black !important;
  font-weight: 500;
  width: 100%;
}

.acc-holder img {
  width: 25px;
  margin-right: 10px;
}

.acc-holder {
  margin-bottom: 20px;
}

.banic img {
  width: 40px;
  height: 40px;

}

.tableload {
  top: 300px;
  left: 49%;
}

.tableload .spinner-border {
  color: brown !important;
}

.uplo-img p {
  font-size: 12px;
}

.uploadfile-name {
  width: 150px;
  background-color: #d9d8d8;
  padding: 4px 5px;
  border-radius: 5px;
  margin: 5px 0px;
}

.remove-upload {
  top: -2px;
  right: 0px;
  cursor: pointer;
  font-size: 16px;
}

.react-date .react-datepicker__input-container input {
  width: 100% !important;
  background-color: #b7b2b221;
  border: none !important;
  border-radius: 5px;
}

.react-date .react-datepicker-wrapper {
  width: 100% !important;
}

.profile-came {
  top: 97px;
  left: 52%;
  font-size: 12px;
  padding: 6px 9px;
  background-color: white;
  border-radius: 30px;
  color: brown;
  cursor: pointer;
}

.upload-docs {
  border-radius: 5px;
  padding: 4px;
  text-align: right;
  background-color: #d9d9d93b;
}

.upload-docs button,
.upload-docs button:hover {
  background-color: #87808029;
  color: #6e6b6b;
}

.upload-docs img {
  width: 20px;
  height: 20px;
}

.schemecard .modal-header h1 {
  font-size: 20px;
}

.MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
}

@media (min-width: 600px) {
  .css-eozsn7 {
    visibility: hidden !important;
    width: auto;
    height: auto;
  }
}

.react-date .MuiStack-root {
  display: block !important;
  padding: 0px !important;
}

.react-date .MuiFormControl-root {
  width: 100% !important;
}

.react-date input {
  font-weight: 500 !important;
  font-size: 15px;
}

.signn-react .css-jupps9-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 0px !important;
}

.signn-react .MuiIconButton-edgeEnd,
.signn-react .MuiIconButton-edgeEnd:hover,
.signn-react .MuiOutlinedInput-root {
  background-color: white !important
}

.signn-react .MuiStack-root {
  border: 1px solid #6962623b !important;
  border-radius: 10px;
  overflow: hidden;
}

.signn-react .MuiInputBase-input {
  border: none !important;
}

.bene-react .MuiFormControl-root {
  border: 1px solid #dbd9d9 !important;
  border-radius: 10px !important;
  padding: 2px 0px !important;
}

.bene-react input {
  border: none !important;
}

.ackey img {
  width: 20px;
  height: 20px;
}

.doc-format {
  font-size: 13px;
}

.MuiToolbar-regular {
  padding: 0px !important;
}

.MuiTablePagination-displayedRows {
  margin: 0px !important;
}

.pd-view {
  overflow: hidden;
}

.pd-view img {
  transition: all 0.5s ease-in-out;
}

.gp_lst:hover .pd-view img {
  transform: scale(1.2);
  transition: all 0.5s ease-in-out;
}

.plan-name-sub h3 {
  font-size: 25px;
}

/* .MuiDataGrid-virtualScrollerRenderZone div:nth-child(5){
  --rowBorderColor: none !important;
} */
.buygoldbtn:hover {
  background-color: black !important;
  color: white !important;
}

.no-commision img {
  width: 500px;
  height: 500px;
}

.faq .shimmer {
  border-radius: 30px;
}

/* Responsive  */

@media (width < 1400px) {
  .container {
    max-width: 1320px;
    width: 100%;
    margin: 0px auto;
  }

  .schematable {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}


@media (width < 1296px) {
  .schemebtn-2 {
    margin-top: 17px;
  }
}

@media (width < 1200px) {
  .container {
    max-width: 960px;
    width: 100%;
    margin: 0px auto;
  }

  .faq .shimmer {
    border-radius: 30px;
  }

  .client-shim .lh-base .shimmer {
    width: 220px !important;
  }

  .abt-img1 .shimmer {
    width: 376px !important;
    height: 376px !important;
  }

  .abt-smimg div .shimmer {
    width: 130px !important;
    height: 120px !important;
  }

  .abt-smimg-title .shimmer {
    width: 456px !important;
    height: 75px !important;
  }

  .prdd-load .shimmer {
    width: 195px !important;
  }

  .prdd-load .mb-4 .shimmer {
    height: 49px !important;
    width: 258px !important;
  }

  .prd-big .shimmer {
    height: 250px !important;
    width: 430px !important;
  }

  .pro_de img {
    width: 70%;
    height: 100%;
    object-fit: cover;
  }

  .rerfs img {
    width: 34px !important;
    height: 34px;
    object-fit: cover;
  }

  .schemebtn-2 {
    margin-top: 0px;
  }

  .top_nav h6 {
    font-size: 13px;
  }

  .top_nav img {
    width: 17px;
    height: 17px;
  }

  .schema_main .top h4,
  .top_active h4,
  .titsksd h5 {
    font-size: 18px;
  }

  .schema_main .schema p {
    font-size: 15px;
  }

  .schemebar h2,
  .titsksd h2,
  .prod-desc h2,
  .price-break h2 {
    font-size: 20px;
  }

  .schema_main .top {
    background: none;
    background-color: #F5F5F5;
  }

  .prod-desc li {
    font-size: 15px !important;
  }

  .schema button {
    padding: 5px 10px;
    font-size: 15px;
  }

  .schema img {
    width: 17px;
    height: 17px;
  }

  .schema {
    padding: 10px;
  }

  .leaft {
    width: 100%;
  }

  .leaft h5 {
    font-size: 14px !important;
  }

  .smallBan img {
    border-radius: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .activescheme {
    background-color: brown;
    border-radius: 20px;
  }

  .sffsfsf img {
    width: 233px;
  }

  .covert img {
    width: 100%;
  }

  .contact-image img {
    max-width: 457px;
  }

  .contact-form form {
    max-width: 600px;
  }

  .contact-form {
    position: absolute;
    top: -127px;
    left: 2%;
  }

  .follow-icon img {
    max-width: 37px;
  }

  .con-office h4 {
    font-size: 20px;
    font-weight: 700;
  }

  .con-office p {
    font-size: 15px;
    font-weight: 500;
  }

  .contact-form .form-group,
  .contact-form label {
    margin: 7px 0px;
    font-size: 16px;
  }

  .contact-form button,
  .submit button {
    padding: 9px 7px;
    font-size: 16px;
  }

  .verifymodal h3:after {
    margin: 5px 160px;
  }

  .balance-view3 {
    width: 322px;
  }

  .aboutsub {
    font-size: 20px;
  }

  .trust img {
    max-width: 115px;
  }



  .balance-view1 {
    height: 150px;
  }

  .text-color2 {
    font-size: 16px !important;
    font-weight: 700;
  }

  .gp_lst button {
    padding: 7px 10px;
    font-size: 13px;
  }

  .prdd h2 {
    font-size: 20px;
  }

  .prdd h5 {
    font-size: 14px;
  }

  .rerfs h5 {
    font-size: 13px;
  }

  .prod-desc h4,
  .rc-table thead th {
    font-size: 15px;
  }

  .rc-table tbody tr td {
    font-size: 15px;
  }

  .simlar_pr img {
    width: 100px;
    height: 100px;
  }

  .breadcamp h4 {
    font-size: 20px;
  }

  .simlar_pr button,
  .simlar_pr button:hover {
    font-size: 12px;
    font-weight: 650;
  }

  .con-loc-icon {
    height: 47px;
    width: 46px;
  }

  .con-loc-icon img {
    width: 20px;
  }

  .lh-lg {
    line-height: 1.5 !important;
    font-size: 25px;
  }

  .balance-view3 {
    left: 0px;
    width: 100%;
    height: 60px;
  }

  .balance-view3 h4 {
    font-size: 16px;
  }

  .bonus h5 {
    font-size: 15px;
  }

  .post-bg2 img,
  .post-bg1 img,
  .post-bg3 img {
    width: 70px;
    height: 70px;
  }

  .post-bg2 button,
  .post-bg1 button,
  .post-bg3 button {
    font-size: 13px;
  }

  .totalprop span {
    font-size: 16px !important;
  }

  .mainIcon {
    width: 75px;
  }

  .bottom_nav .nav-link {
    font-size: 16px;
  }

  .top-drop img {
    width: 32px !important;
    height: 32px !important;
  }

  .top-drop .dropdown-menu li {
    font-size: 15px;
  }

  .head_bn h2 {
    font-size: 30px;
  }

  .titsksd img {
    width: 100px;
    object-fit: cover;
  }

  .terms-text span {
    font-size: 15px !important;
  }

  .terms-text::before {
    width: 141px !important;
  }

  .wealth_smart h2 {
    font-weight: 700;
    font-size: 23px;
  }

  .wealth_smart img {
    width: 57px;
    height: 53px;
  }

  .wealth_smart button {
    padding: 10px 18px;
    font-size: 15px;
  }

  .convert-head {
    font-size: 20px !important;
    line-height: 35px !important;
  }

  .convert-sb-head {
    font-size: 18px;
  }

  .convert-para {
    font-size: 14px;
  }

  .covert button {
    padding: 7px 13px;
    font-size: 14px;
  }

  .smalImg {
    width: 16px !important;
    height: 15px !important;
  }

  .schemebtn.nav-link,
  .schemebtn.nav-link.active {
    padding: 13px 23px !important;
    font-size: 15px !important;
  }

  .accordion-header button {
    padding: 15px;
    font-size: 15px;
  }

  .bot_foot h6 {
    font-size: 14px;
  }

  .bot_foot h5 {
    font-size: 17px;
  }

  .folle img {
    width: 20px;
    height: 20px;
  }

  .top_nav p {
    font-size: 13px;
  }

  .about-content h4 {
    font-size: 16px !important;
  }

  .about-content h2 {
    line-height: 35px !important;
    font-size: 23px;
  }

  .delvdd h4 {
    font-size: 16px;
  }

  .delvdd img {
    width: 30px;
    height: 30px;
  }

  .delvdd {
    height: 120px;
    width: 130px;
  }

  .svvv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .pshycal img {
    width: 40px;
    height: 40px;
  }

  .pshycal p {
    font-size: 13px !important;
  }

  .pshycal h5 {
    font-size: 17px !important;
  }

  .gp_lst img {
    width: 115px;
    height: 115px;
    object-fit: cover;
  }

  .gp_lst .one_line {
    font-size: 15px;
  }

  .goldsiver-box .nav-link {
    font-size: 18px;

  }

  .goldrate-gm h3 {
    font-size: 18px;
  }

  .livebuy h5 {
    font-size: 14px;
  }

  .detail-imges .tab-pane img {
    height: 260px !important;
  }

  .dashboard-list img {
    max-width: 21px;
    width: 100%;
  }

  .dashboard-list .page-name h4 {
    font-size: 15px;
  }

  .bd-form img {
    width: 72px;
    height: 72px;
  }

  .profile-came {
    top: 81px !important;
    font-size: 10px !important;
    padding: 3px 6px !important;
  }

  .login-text input,
  .login-text select,
  .referenc-idlist {
    font-size: 14px !important;
  }

  .login-text label {
    font-size: 13px !important;
  }

  .upload-docs button,
  .upload-docs button:hover {
    font-size: 14px !important;
  }

  .upload-docs img {
    width: 14px !important;
    height: 13px !important;
    object-fit: contain !important;
  }

  .aclist button,
  .aclist button:hover,
  .addbank-submit button,
  .update-profile button,
  .verify-kyc button,
  .addbank-submit button:hover {
    width: 110px !important;
    font-size: 14px !important;
  }

  .no-commision img {
    width: 340px !important;
    height: 340px !important;
    object-fit: cover !important;
  }

  .statustact {
    padding: 0px !important;
  }

  .plan-time p {
    font-size: 13px
  }

  .plan-rate button {
    font-size: 11px !important;
  }

  .siptrans-head h4 {
    font-size: 18px !important;
  }

  .siptrans-head img {
    width: 17px !important;
  }

  .secondplan-btn {
    width: 100px;
    height: 38px;
    font-size: 13px;
  }

  .dg-scheme .leaft2 h5 {
    font-size: 13px;
  }

  .docimg {
    width: 90px !important;
    height: 91px !important;
    object-fit: cover !important;
  }

  .diselect {
    top: 15px !important;
    left: 56px !important;
  }

  .notify-sec1 h4 {
    font-size: 16px;
    font-weight: 650;
  }

  .login-header h3 {
    font-weight: 700;
    font-size: 20px;
  }

  .login-header .modal-title {
    text-align: center !important;
  }

  .login-header h3 {
    font-size: 20px;
  }

  .signarrow {
    font-size: 16px;
    width: 26px;
    height: 26px;
  }

  .go-signup {
    font-size: 15px;
  }

  .login-footer h4 {
    font-size: 13px;
  }

  .loginmodal button,
  .loginmodal button:hover,
  .signupmodal button,
  .signupmodal button:hover,
  .verifymodal button,
  .verifymodal button:hover {
    font-size: 16px;
  }

  .login-terms p {
    font-size: 13px;
  }

  #frgtpwd_modal button,
  #resetmodal button {
    font-size: 16px !important;
  }

  .forgotmodal p {
    font-size: 14px !important;
    margin-top: 15px;
  }

  .resetmodalbox h3::after {
    margin: 5px 263px !important;
  }

  .pd-tableload th {
    font-size: 15px;
    padding: 15px 15px;
  }

  .pl-imgname .shimmer {
    width: 100% !important;
  }


}

@media (width < 992px) {
  .container {
    max-width: 720px;
    width: 100%;
    margin: 0px auto;
  }

  .schemebtn.nav-link,
  .schemebtn.nav-link.active {
    font-size: 14px !important;
  }

  .scheme-ban .shimmer {
    height: 250px !important;
  }

  .abt-smimg-title .shimmer {
    width: 342px !important;
    height: 70px !important;
  }

  .abt-smimg div .shimmer {
    width: 117px !important;
    height: 109px !important;
  }

  .abt-img1 .shimmer {
    width: 270px !important;
    height: 279px !important;
  }

  .abt-trust .shimmer {
    height: 100px !important;
    width: 120px !important;
  }

  .prd-big .shimmer {
    height: 250px !important;
    width: 329px !important;
  }

  .prdd-load .mb-4 .shimmer {
    height: 49px !important;
    width: 258px !important;
  }

  .prdd-load .shimmer {
    width: 139px !important;
  }

  .productdetail-shimmer .shimmer {
    width: 500px !important;
  }

  .simlar_pr .mb-3 .shimmer {
    height: 100px !important;
  }



  .pl-img .shimmer {
    height: 100px !important;
    width: 100px !important;
  }

  .schemebtn-1,
  .schemebtn-3 {
    margin-top: 20px;
  }

  .schemescroll {
    overflow-x: scroll;
  }

  .leaft {
    width: 200px;
  }

  .schematable {
    overflow-x: scroll;
    overflow-y: hidden;
    top: 0px;
    left: 0px;
  }

  .wealth_smart button {
    padding: 10px 13px;
    font-size: 15px;

  }

  .customer-pages {
    display: none;
  }

  .mb-pages {
    display: block;
  }

  .mb-pages button {
    background-color: #991313 !important;
    border: none !important;
    color: white !important;
  }

  .mb-pages .btn-close:focus {
    box-shadow: none;
    opacity: 0 !important;
  }

  .sffsfsf img {
    width: 235px;
  }

  .sffsfsf {
    position: absolute;
    top: 0px;
    left: -14px;
  }

  .covert img {
    max-width: 400px;
    width: 100%;
  }

  .covert button {
    padding: 10px 19px;
    font-size: 15px;
  }

  .accordion-header button {
    padding: 10px;
  }

  .lh-lg {
    line-height: 1.5 !important;
    font-size: 25px;
  }

  .delvdd {
    height: 110px;
    width: 120px;
  }

  .aboutsub {
    font-size: 17px;
  }

  .trust img {
    max-width: 120px;
  }

  .gp_lst img {
    width: 140px;
    height: 150px;
    object-fit: cover;
  }

  .gp_lst button {
    padding: 6px 11px;
    font-size: 13px;
  }

  .gp_lst h5 {
    font-size: 18px;
  }

  .amt input,
  .grm input {
    max-width: 130px;
    width: 100%;
  }

  .goldsiver-box {
    max-width: 370px;
    width: 100%;
  }

  .goldsiver-box button {
    font-size: 19px !important;
  }

  .prod-desc h2,
  .price-break h2 {
    font-size: 25px;
  }

  .prod-desc .dots:nth-child(2) {
    width: 20px;
    height: 20px;
  }

  .con-loc-icon img {
    background-color: #991313;
    max-width: 45px;
  }

  .con-office p {
    font-size: 13px;
    font-weight: 500;
  }

  .follow-icon img {
    max-width: 32px;
  }

  .balance-view3 {
    left: 4px;
    width: 327px;
  }

  .head_bn h2 {
    font-size: 30px;
  }

  .top_nav img {
    width: 16px;
    height: 16px;
  }

  .toggle-bar-mb {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .toggle-bar-mb img {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    object-fit: cover;
  }

  .toggle-bar-mb .dropdown-toggle::after {
    content: none !important;
  }

  .mb-user button {
    font-size: 14px;
  }

  .login-terms h6 {
    font-size: 12px;
  }

  .mb-login {
    font-size: 15px;
  }

  .wealth_smart h2 {
    font-weight: 700;
    font-size: 20px;
  }

  .wealth_smart h5 {
    font-size: 15px;
  }

  .activescheme {
    width: 347px;
  }

  .accordion-header button {
    font-size: 13px;

  }

  .covert img {
    max-width: 300px;
    width: 100%;
  }

  .accordion-body p {
    font-size: 12px;
  }

  .about-content h2 {
    line-height: 35px !important;
    font-size: 20px;
  }

  .about-content h4 {
    font-size: 15px !important;
  }

  .gp_lst img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  .con-office h4 {
    font-size: 17px;
    font-weight: 700;
  }

  .follow-icon img {
    max-width: 16px;
  }

  .detail-imges .tab-pane img {
    height: 260px !important;
  }

  .rerfs {
    padding: 10px;
  }

  .prod-desc h2,
  .price-break h2 {
    font-size: 18px;
  }

  .prod-listview li {
    margin: 10px 0px;
  }

  .tableload {
    top: 0px;
    left: 44%;
  }

  .plan-count h4 {
    font-size: 15px;
  }

  .plan-count h2 {
    font-size: 18px;
  }

  .mb-tableload {
    top: 118% !important;
    left: 44%;
  }

  .head_bn h2 {
    font-size: 25px;
  }

  .bd-head h4 {
    font-size: 18px;
    font-weight: 650;
  }

  .myaccount-title h2 {
    font-size: 22px;
  }

  /* .ft-md-head,
  .ft-md-lists {
    padding-left: 65px;
  } */

  .navbar-toggler-icon {
    width: 22px !important;
    height: 22px !important;
  }

  .toogle-bar-mb .navbar-toggler {
    padding: 7px 12px !important;
    color: rgb(153 19 19) !important;
    border: var(--bs-border-width) solid rgb(101 0 32) !important;
  }

}

@media (width < 768px) {
  .container {
    max-width: 540px;
    width: 100%;
    margin: 0px auto;
  }

  .schemebtn-1,
  .schemebtn-3 {
    margin-top: 0px;
  }


  .border-loc {
    border-left: 1px solid #3f38383b;
    border-right: none;
  }

  .top_nav p {
    font-size: 14px;
  }


  .ft-md-head,
  .ft-md-lists {
    padding: 0px;
  }

  .schema_main {
    /* height: 408px; */
    /* width: 360px; */
  }

  .breadcamp h4,
  .covert h5,
  .covert p {
    font-size: 15px;
  }

  .convert-head {
    font-size: 18px !important;
  }

  .covert button {
    padding: 10px 19px;
    font-size: 11px;
  }

  .scheme-qus button {
    width: 215px;
    height: 50px;
  }

  .schema_main .schema p {
    font-size: 13px;
    font-weight: 600;
  }

  .schema button {
    padding: 9px 12px;
    font-size: 14px;
  }

  .schemebar h2,
  .titsksd h2 {
    font-size: 25px;
  }

  .sffsfsf img {
    width: 109px;
  }

  .sffsfsf {
    position: absolute;
    top: 0px;
    left: -15px;
  }

  .wealth_smart h2,
  .top_foot h2 {
    font-size: 20px;
  }

  .covert img {
    max-width: 290px;
    width: 100%;
  }

  .top_foot button {
    padding: 7px 17px;
  }


  .contact-form {
    position: absolute;
    top: -102px;
    left: 3%;
  }

  .contact-form button,
  .submit button {
    padding: 10px 8px;
    font-size: 13px;
    width: 109px;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 9px;

  }

  .myaccount-title h2 {
    font-size: 20px;
  }

  .bd-head h4 {
    font-size: 15px;
  }

  .balance-view3 {
    width: 239px;
  }

  .balance-view3 h4 {
    font-size: 16px;
  }

  .sipshow-search {
    padding: 15px 6px;
  }

  .gp_lst img {
    width: 100px;
    height: 110px;
    object-fit: cover;
  }

  .gp_lst h5 {
    font-size: 16px;
  }

  .text-color2 {
    font-size: 15px !important;
    font-weight: 700;
  }

  .gp_lst button {
    padding: 8px 10px;
    font-size: 12px;
    font-weight: 650;
  }

  .rc-table thead.rc-table-thead th,
  .pd-tableload th {
    padding: 20px 10px;
    font-size: 14px;
  }

  .rc-table tr.rc-table-row td {
    padding: 18px 10px;
    font-size: 13px;
  }

  .prod-desc li {
    font-size: 13px !important;
    font-weight: 650;
  }

  .prod-desc h2,
  .price-break h2 {
    font-size: 18px;
  }

  .prod-desc h4 {
    font-size: 15px;
  }

  .prdd h2 {
    font-size: 18px;
  }

  .rerfs {
    padding: 25px 15px;
  }

  .scheme-mbbox {
    display: flex;
    justify-content: center;
  }

  .goldsiver-box .nav-link {
    font-size: 15px !important;
  }

  .goldrate-gm h3 {
    font-size: 15px;
  }

  .schemebar h2,
  .titsksd h2 {
    font-size: 20px;
  }

  .schema_main .top h4,
  .top_active h4,
  .titsksd h5 {
    font-size: 16px;
  }

  .schema_main .log {
    width: 40px !important;
    height: 35px !important;
    object-fit: cover !important;
  }

  .leaft h5 {
    font-size: 13px !important;
  }

  .leaft {
    width: 186px;
  }

  .smallBan img {
    width: 152px;
    height: 207px;
    object-fit: contain;
  }

  .wealth_smart button {
    padding: 7px 9px;
    font-size: 13px;
  }

  .whych-shim .shimmer {
    width: 180px !important;
  }

  .pl-imgname .shimmer {
    height: 24px !important;
    width: 100% !important;
  }

  .abt-img1 .shimmer {
    width: 417px !important;
    height: 279px !important;
  }

  .abt-smimg-title .shimmer {
    width: 100% !important;
    height: 70px !important;
  }

  .abt-smimg div .shimmer {
    width: 85px !important;
    height: 76px !important;
  }

  .pshycal {
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
  }

  .prdd-load .shimmer {
    width: 100% !important;
  }

  .prd-big .shimmer {
    height: 250px !important;
    width: 430px !important;
  }

  .pshycal img {
    width: 30px;
    height: 30px;
  }

  .pshycal h5 {
    font-size: 15px !important;
  }

  .svvv img {
    width: 45%;
  }

  .delvdd h4 {
    font-size: 14px;
  }

  .delvdd img {
    width: 22px;
    height: 23px;
  }

  .delvdd {
    height: 80px;
    width: 95px;
  }

  .about-content h2 {
    font-size: 17px;
  }

  .trust img {
    max-width: 98px;
  }

  .gp_lst img {
    width: 73px;
    height: 94px;
    object-fit: contain;

  }

  .accordion-header button,
  .accordion-body p {
    font-size: 14px;
  }

  .bottom_nav .nav-link {
    font-size: 14px;
    font-weight: 650;
  }

  .toogle-bar-mb span {
    font-size: 13px;
  }



  .mainIcon {
    width: 63px;
  }

  .covert img {
    max-width: 222px;
    width: 100%;
  }

  .scheme-qus button {
    width: 202px;
    height: 46px;
  }

  .schemebtn.nav-link,
  .schemebtn.nav-link.active {
    font-size: 13px !important;
  }

  .bot_foot h6 {
    font-size: 13px;
  }

  .text-color2 {
    font-size: 13px !important;
    font-weight: 700;
  }

  .gp_lst .one_line {
    font-size: 13px;
  }

  .simlar_pr img {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }

  .simlar_pr .gap-2 p {
    font-weight: 650;
    font-size: 13px;
  }

  .detail-imges .tab-content {
    margin: 0px 40px;
  }

  .contact-image img {
    max-width: 520px;
  }

  .customer-name h4 {
    font-size: 15px;
    font-weight: 650;
  }

  .balance-view3 {
    flex-direction: column;
  }

  .balance-view3 {
    height: 80px;
  }

  .addbank-btn button {
    padding: 10px 12px;
    font-size: 13px;
  }

  .login-header h3 {
    font-size: 18px;
  }

  .resetmodalbox h3::after {
    margin: 5px 203px !important;
  }


}

@media (width < 576px) {
  .container {
    max-width: 370px;
    width: 100%;
    margin: 0px auto;
  }

  .border-loc {
    border-left: none;
  }

  .head_bn h2 {
    font-size: 20px;
  }

  .schema div:last-child {
    display: flex !important;
    justify-content: center;
    padding: 5px 0px;
  }

  .schema_main {
    margin-bottom: 15px;
  }

  .leaft {
    width: 180px;
  }

  .schema_main .schema p,
  .schema2 .text-center h5 {
    font-size: 15px;
  }

  .lh-lg {
    line-height: 1.5 !important;
    font-size: 20px;
  }

  .rerfs {
    padding: 25px 0px;
  }

  .rerfs h5 {
    font-size: 13px;
  }

  .svvv img {
    width: 100%;
  }

  .login-terms span,
  .login-footer span {
    font-size: 13px;
  }

  .loginmodal input {
    padding: 10px 10px;
  }

  .login-terms h6 {
    font-size: 13px;
  }

  .login-header h3 {
    font-size: 18px;
    text-align: center;
  }

  .otp-box input {
    max-width: 42px;
    width: 100px;
    padding: 8px 8px;
    border-radius: 10px;
    text-align: center;
  }

  .verifymodal h3:after {
    margin: 5px 91px;
  }

  .verifymodal .login-terms h6,
  .verifymodal .modal-header h4 {
    font-size: 13px;
  }

  .schemebar h2,
  .titsksd h2 {
    font-size: 18px;
  }

  .wealth_smart img {
    width: 40px;
    height: 43px;
  }

  .logout-text {
    color: #000;
    font-size: 16px;
    font-weight: 650;
  }

  .logouticon {
    color: #991313;
    font-size: 30px;
  }

  .logout button {
    font-size: 14px;
    padding: 5px 20px;
  }

  .wealth_smart button {
    padding: 8px 13px;
    font-size: 12px;
  }

  .mainIcon {
    width: 50px;
    height: 50px;
  }

  .about-content h2 {
    line-height: 26px !important;
    font-size: 15px;
  }

  .about-content h4 {
    font-size: 12px !important;
  }

  .titsksd h5 {
    font-size: 15px;
  }

  .wealth_smart h2,
  .top_foot h2 {
    font-size: 17px;
  }

  .wealth_smart h5 {
    font-size: 14px;
  }

  .covert p {
    font-size: 13px;
  }

  .covert img {
    max-width: 220px;
    width: 100%;
  }

  .sffsfsf img {
    width: 114px;
  }

  .sffsfsf {
    position: absolute;
    top: 3px;
    left: -33px;
  }

  .delvdd img {
    width: 23px;
    height: 24px;
  }

  .refreimg img {
    width: 30px !important;
  }

  .delvdd h4 {
    font-size: 13px;
  }

  .detail-imges .tab-pane img {
    height: 202px !important;
  }

  .pro_de img {
    width: 73%;
    height: 74%;
    object-fit: cover;
  }

  .delvdd {
    height: 90px;
    width: 100px;
  }

  .contact-image {
    visibility: hidden;
  }

  .contact-form {
    position: absolute;
    top: -100px;
    left: 6%;
  }

  .bd-form .profice-pic {
    padding: 15px 0px;
  }

  .bd-form img {
    max-width: 78px;
  }

  .bd-form input,
  .bd-form input::placeholder,
  .bd-form select {
    padding: 10px 5px;
    font-size: 13px !important;
  }

  .bd-form label,
  .contact-form label,
  .Addbankacc-modal label {
    font-size: 13px !important;
  }

  .profile-came {
    top: 97px;
    left: 56%;
  }

  .myaccount-title h2 {
    font-size: 18px;
  }

  .folle img {
    width: 16px;
    height: 16px;
  }

  .toggle-bar-mb .dropdown-menu li,
  .toggle-bar-mb .dropdown-menu span {
    font-size: 13px;
  }

  .profile-came {
    top: 60px !important;
  }

  .upload-docs button,
  .upload-docs button:hover {
    font-size: 12px !important;
  }

  .balance-view3 {
    left: 20px;
    width: 327px;
  }

  .balance-view3 {
    width: 300px;
  }

  .siptable-view table {
    width: 600px !important;
  }

  .goldsiver-box button {
    font-size: 15px !important;
  }

  .goldrate-gm h6 {
    font-size: 10px;
    color: gray;
  }

  .livebuy img {
    max-width: 15px;
    width: 100%;
  }

  .livebuy h5 {
    font-size: 12px;
  }

  .mb-schem {
    border: 1px solid #d2d2d2 !important;
    border-radius: 10px !important;
    padding: 0px !important;
  }

  .mb-schem-head {
    background-color: #f6eaea;
    border-radius: 10px 10px 10px 10px;
  }

  .mb-sch-img img {
    width: 37px;
    height: 40px;
  }

  .mb-sch-img {
    height: 36px;
  }

  .mb-sch-name h5 {
    margin: 10px 0px 0px 0px;
    color: #991313 !important;
    font-size: 15px;
  }

  .accordion-collapse .schema p {
    font-size: 14px;
  }

  .schemebtn-1 {
    margin-top: 40px;
  }

  .mb-scheme-box {
    transition: all 0.5s ease-in-out;
  }

  .mb-scheme-box .show {
    height: 340px;
    transition: all 0.5s ease-in-out;
  }

  .toggle-bar-mb img {
    width: 41px;
    height: 41px;
    border-radius: 50px;
    object-fit: cover;
  }

  .top_nav {
    height: 46px;
  }

  .mb-logo img {
    width: 37px;
    height: 37px;
    padding: 5px 4px;
    background-color: white;
    border-radius: 40px;
  }

  .smallBan img {
    width: 100%;
    height: 100%;
  }

  .aclist h2 {
    font-size: 13px;
    margin-bottom: 25px;
    font-weight: 600;
    color: black;
  }

  .ackey img {
    width: 15px;
    height: 16px;
  }

  .con-loc-icon img {
    width: 15px;
  }

  .con-loc-icon {
    height: 39px;
    width: 39px;
  }

  .contact-form textarea::placeholder {
    font-size: 13px;
  }

  .contact-form button,
  .submit button {
    padding: 7px 8px;
    font-size: 13px;
    border-radius: 7px;
    width: 84px;
  }

  .contact-form form {
    padding: 18px;
  }

  .bot_foot h5 {
    font-size: 15px;
  }

  .top_nav p {
    font-size: 11px;
  }

  .bonus-pic {
    text-align: center;
    margin-top: 13px;
  }

  .bonus-pic img {
    max-width: 60px;
    width: 100%;
  }

  .login-text .text-danger,
  .signup-detailform .text-danger {
    font-size: 10px;
  }

  .mbacnum {
    margin-top: 20px;
  }

  .select__menu {
    height: 150px !important;
    overflow-y: hidden;
  }

  .forgotmodal h3::after,
  .resetmodalbox h3::after {
    margin: 8px 122px !important;
  }

  .MuiDataGrid-toolbarQuickFilter {
    width: 160px !important;
  }

  .MuiInput-underline {
    font-size: 12px !important;
  }

  .modal-header h3,
  .modal-header h1 {
    font-size: 18px !important;
  }

  .pop-cancel-icon img {
    width: 40px !important;
  }

  .plan-name-sub h3 {
    font-size: 19px;
  }

  .plan-name-sub p {
    font-size: 13px;
    font-weight: 500;
  }

  .scmcanc button {
    font-size: 13px;
  }

  .secondplan-btn {
    width: 100px;
    height: 30px;
  }

  .schowner h5 {
    font-size: 14px;
    font-weight: 650;
  }

  .schemecard .modal-header h1 {
    font-size: 18px;
  }

  .schemecard .modal-body small,
  .schemecard .modal-body label,
  .schemecard .modal-body span,
  .schemecard .modal-body p {
    font-size: 13px;
  }


  .schemecard .modal-body input,
  .schemecard .modal-body select {
    padding: 10px !important;
  }

  .qq-sacn-img img {
    width: 125px;
    height: 125px;
    object-fit: fill;
  }

  .qr-upiid h6,
  .qr-detail h6 {
    font-size: 13px;
  }

  .uplo-img button,
  .uplo-img button:hover,
  .uploadfile-name input {
    font-size: 13px;
  }

  .acc-holder img {
    width: 23px;
  }

  .acc-holder span {
    font-size: 14px;
    font-weight: 650;
  }

  .ac-holdbank h5 {
    font-size: 17px;
  }

  .pop-submsg h4,
  .pop-submsg2 h4 {
    font-size: 15px;
  }

  .addbank-info h5 {
    font-size: 17px;
  }

  .navbar>.container {
    display: block;
  }

  .toggle-bar-mb {
    justify-content: space-between;
  }

  .body_top {
    margin-top: 103px;
  }

  .siptrans-head h4,
  .mybank-head h4,
  .bd-heads h4,
  .notificatin-head h4 {
    color: #6c0000;
    font-weight: 650;
    font-size: 18px;
  }

  .aclist .col-6 {
    padding: 0px;
  }

  .aclist button,
  .aclist button:hover,
  .addbank-submit button,
  .update-profile button,
  .verify-kyc button,
  .addbank-submit button:hover {
    padding: 6px 27px;
    border-radius: 10px;
    font-size: 16px;
  }

  .mb-acnum {
    padding-bottom: 26px;
  }

  .docimg {
    padding: 0px !important;
    height: 66px !important;
    width: 66px !important;
    border-radius: 9px !important;
  }

  .diselect {
    top: 6px !important;
    left: 46px !important;
  }

  .notify-img img {
    max-width: 45px;
    width: 100%;
    border-radius: 95px;
    height: 45px;
    object-fit: cover;
  }

  .mb-logname {
    font-size: 13px;
  }

}

/* @media screen and (max-width:1500px) {
  .goldsiver-box {
    bottom: -190px !important;
    left: 100px !important;
  }
}

@media screen and (min-width: 1200px) {
  .goldsiver-box {
    bottom: -210px !important;
    left: 7px !important;
  }
}

@media screen and (min-width: 1300px) {
  .goldsiver-box {
    bottom: -210px !important;
    left: 33px !important;
  }
} */


@media screen and (max-width:1500px) {
  .goldsiver-box {
    bottom: -90px !important;
    left: 100px !important;
  }
}

@media screen and (min-width: 1200px) {
  .goldsiver-box {
    bottom: -111px !important;
    left: 7px !important;
  }
}

@media screen and (min-width: 1300px) {
  .goldsiver-box {
    bottom: -111px !important;
    left: 33px !important;
  }
}


/*  */

#blinking-button {
  padding: 8px 35px;
  border-radius: 48px 0px;
  border: 0px solid #6C8003;
}

@keyframes blink {

  0%,
  100% {
    background-color: #650020;
  }

  50% {
    background-color: #ce9e30;
  }
}

#blinking-button {
  background-color: #8EDDBE;
  color: white;
  animation: blink 1s linear infinite;
  font-weight: 600;
  margin-bottom: 8px;
}

.top_active span {
  font-size: 16px;
  margin-left: 15px;
}

/* Visit Website  */
#container {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #8EDDBE;
}

#inner-div {
  text-align: center;
  padding: 20px;
}



.center_banner_img img {
  width: 100%;
}

.center_banner_img {
  padding-top: 40px;
  padding-bottom: 40px;
}


.benefits_flx {
  display: flex;
  align-items: center;
  gap: 15px;
}


.home_sceme .schema2 {
  display: none;
}


.home_sceme .center_banner {
  display: none;
}


/*  */

@media screen and (max-width:1199px) {
  .benefits_flx {
    display: block;
    text-align: center;
  }

  .schema_main .log {
    margin-bottom: 15px;
  }

  .home_sceme {
    margin-top: 20px !important;
  }

  .ex_btn {
    left: 20% !important;
  }

  .sc_head h1 {
    font-size: 36px !important;
  }

  .grams_pos {
    right: 30px !important;
  }

  .details span {
    font-size: 13px !important;
  }

  .quick_img .schemebtn-1 {
    width: 100% !important;
  }

  .plan_img img {
    width: 100% !important;
  }
}


/* 991 css */

@media screen and (max-width:991px) {
  .center_banner_img {
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .ex_btn button {
    padding: 3px 10px 3px 15px !important;

  }

  .ex_btn {
    top: 30% !important;
  }

  .whatsapp_icon {
    right: 145px !important;
  }

  .grams_pos {
    right: 80px !important;
  }
}

.details span {
  color: #881e41 !important;
  text-decoration: underline !important;
  font-weight: 600;
  border: none;
}




.age_head h4 {
  color: #9e1f1f;
  font-weight: 600;
}

.age_head {
  text-align: center;
}

.says_c .swiper-pagination {
  position: static;
}

.whatsapp_icon img {
  width: 40px;
}



.whatsapp_icon {
  position: fixed;
  bottom: 80px;
  right: 160px;
  z-index: 9;
}

.hepl_text {
  position: absolute;
  right: 30px;
  bottom: 46px;
}

.hepl_text h6 {
  padding: 3px 5px;
  border-radius: 6px;
  background: white;
}

.num_s {
  position: absolute;
  top: 14px;
  left: 6px;
  border: 1px;
}

.login-email input {
  padding: 10px 15px 10px 45px;
  border: 1px solid #dddddd;
}

.num_s::before {
  content: "";
  background: #afafaf;
  position: absolute;
  top: -6px;
  height: 30px;
  width: 1px;
  right: -7px;
}

.ex_btn img {
  width: 25px;
  height: 25px;
}

.ex_btn button {
  border: none;
  background: white;
  padding: 6px 15px 6px 25px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #861211;
  font-weight: 600;
}

.ex_btn {
  position: absolute;
  top: 25%;
  left: 23%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ex2 {
  display: none;
}

.part_button button {
  border: none;
  background-color: #750f36;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
}

.part_button {
  margin-right: 10px;
}

.home_sceme {
  margin-top: 120px;
}

/* 450 css */

@media screen and (max-width:450px) {
  .ex1 {
    display: none !important;
  }

  .ex2 {
    display: block !important;
  }

  .ex_btn {
    top: 28% !important;
    left: 23% !important;
  }

  .sc_img img {
    height: 290px !important;
  }

  .sc_head h1 {
    font-size: 20px !important;
  }

  .ex_head h3 {
    font-size: 16px !important;
  }

  .sav_bg img {
    width: 100% !important;
  }

  .num_p {
    align-items: baseline !important;
  }

  .num_p p {
    display: block !important;
  }

  .sce_btn {
    text-align: center;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .whatsapp_icon {
    right: 20px !important;
    bottom: 56px !important;
  }

  .hepl_text {
    right: 70px !important;
    bottom: 18px !important;
  }

  .toggle-bar-mb img {
    width: 25px !important;
    height: 25px !important;
  }


  .C1 {
    height: 70px;
  }

  .G1 {
    height: 70px;
  }

  .m1 {
    height: 70px !important;
  }

  .F1 {
    height: 70px;
  }
}


.cit_table .leaft {
  width: 210px;
}

.m1 {
  background-color: #5da1ac;
  height: 91px;
}

.m2 {
  background-color: #5da1ac3d;
}

.m3 {
  background-color: #5da1ac57;
}

.m4 {
  background-color: #ffea7b;
}

.m5 {
  background-color: #5da1ac9c;
}

.m6 {
  background-color: #5da1acad;
}


.m7 {
  background-color: #5da1acbf;
}

.m8 {
  background-color: #5da1acc9;
}

.m9 {
  background-color: #5da1acc2;
}

.m10 {
  background-color: #5da1acd9;
}

.m11 {
  background-color: #5da1acf0;
}

.m12 {
  background-color: #5da1ac;
}

.m13 {
  background-color: #5da1ac;
}

.d1 {
  background-color: #6da274;
  height: 75px;
}

.mmm {
  background-color: #5da1ac85;
}

.app_h {
  height: 100px;
}

.lst_c {
  height: 88px;
}

.plan_img img {
  width: 90%;
  border: 10px solid #bf7e1a;
  border-radius: 15px;
}

.quick_img {
  position: relative;
}


.quick_img .schemebtn-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
}

.sc_img img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  height: 350px;
}

.sc_head h1 {
  color: #650020;
  font-weight: 800;
  font-size: 42px;
  text-align: center;
}

.ex_head h3 {
  color: #ffffff;
  font-weight: 800;
  font-size: 20px;
  margin: 0px;
}

.ex_keds {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.sssssshape_img img {
  width: 110px;
}

.sssssshape_img {
  text-align: center;
}

.icon_img img {
  width: 50px;
}

.sav_flx p {
  margin-bottom: 0px;
  color: #000;
  font-weight: 600;
  font-family: "Poppins";
}

.sav_flx {
  display: flex;
  align-items: center;
}

.sav_flx span {
  color: #650020;
  font-weight: 900;
  font-family: "Roboto";
  font-size: 18px;
  text-shadow: 2px 2px #9f9f9f;
}


.grams_num h4 {
  font-size: 18px;
  font-weight: 700;
}

.grams_num h1 {
  font-weight: 800;
  font-size: 60px;
  margin-bottom: 0px;
  font-family: "Abril Fatface";
}

.grams_num {
  display: flex;
  align-items: center;
  gap: 5px;
}

.num_p p {
  display: flex;
  align-items: center;
  gap: 15px;
}

.gem_nun h3 {
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 0px;
  text-transform: uppercase;
  text-shadow: 2px 2px #9f9f9f;
}

.straight_img img {
  width: 100%;
}

.sceeme_shadow {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 25px;
  padding: 20px 20px 0px;
}

/* .ex_head {
  background-image: url(/public/assets/images/sav_bg.png);
} */

.sav_bg img {
  width: 50%;
}

.sav_bg {
  text-align: center;
}

.ex_keds {}

.ex_head {
  position: relative;
}

.sce_btn button {
  border: none;
  background-color: #650020;
  padding: 8px 30px;
  border-radius: 15px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.sce_btn button:hover {
  background-color: #650020;
  color: white;
}

.sce_btn img {
  width: 20px;
}

.cance_policy a {
  color: #650020 !important;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}

.cance_policy a::before {
  content: "";
  background: #650020;
  height: 2px;
  width: 200px;
  position: absolute;
  bottom: 0px;
  left: 14px;
}

.sce_btn {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.grams_pos {
  position: absolute;
  top: -40px;
  right: 100px;
}

.nd_s {
  align-items: baseline;
}

.nd_s .gem_nun h3 {
  margin-left: 45px;
}

@media screen and (max-width:767px) {
  .grams_pos {
    position: static !important;
  }

  .sasssssses .num_p p {
    display: block !important;
  }
}



.footer_bg {
  background-image: url(/public/assets/images/fgfgff.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}


.footer_bg::before {
  content: "";
}

.footer_contents {
  color: black;
  font-size: 16px;
  font-weight: 700;
}

.uselink h6 {
  color: black;
  font-size: 16px;
  font-weight: 700;
}

.folle h6 {
  color: black;
  font-size: 16px;
  font-weight: 700;
}


.help2 {
  display: none;
}

@media screen and (max-width:500px) {
  .help1 {
    display: none !important;
  }

  .help2 {
    display: block !important;
  }

  .css-1msh791 div {
    gap: 10px !important;
  }

  .whatsapp_icon img {
    width: 35px !important;
  }
}


/* 991 */

@media screen and (max-width:991px) {
  .border_right::before {
    display: none;
  }
}


/* 450 css */

@media screen and (max-width:450px) {
  .play_img img {
    width: 75px !important;
  }

  .play_img_flx {
    gap: 1px !important;
  }
}

/* 400 css */

@media screen and (max-width:400px) {
  .goldsiver-box {
    max-width: 350px;
  }
}


.req_btn button {
  background: #650020;
  color: white;
  padding: 5px 15px;
  border-radius: 10px;
  border: none;
}

.req_btn {
  text-align: end;
}

.sav_btn button {
  background: #650020;
  color: white;
  padding: 5px 15px;
  border-radius: 10px;
  border: none;
  width: max-content;
}

.sav_btn button:hover {
  background: #650020;
  color: white;
  padding: 5px 15px;
  border-radius: 10px;
  border: none;
  width: max-content;
}

.sav_btn {
  margin-top: 10px;
  text-align: end;
}

.pay_outmodal {
  width: 300px;
  margin: auto;
}

.footer_social_icons {
  background: #991313;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_social_icons img {
  width: 18px;
  height: 18px;
}

.play_img img {
  width: 100px;
  height: auto;
}

.play_img_flx {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}


.autocomplete-container {
  position: relative;
  width: 300px;
}

.autocomplete-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  z-index: 10;
  margin-top: 2px;
}

.autocomplete-item {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-item:hover {
  background-color: #f0f0f0;
}

.border_right {
  position: relative;
}

.border_right::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 65px;
  width: 2px;
  background: #efefef;
}